import React, { useReducer } from "react"
import styled from "@emotion/styled"
import gs from "../styles/globals"
import Layout from "../components/Layout"
import { Main } from "../components/sections/Content"
import { DivDefaultWrapper } from "../components/parts/General"
import { Input, Label } from "../components/parts/General"
import Button from "../components/parts/Button"
import { Title } from "../components/parts/General"
import Seo from "../components/Seo"
import Loading from "../components/parts/LoadingRing"

// #region styling
const Form = styled.form`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  margin: 0 0 20px 0;
`
const DivField = styled.div`
  margin: 0 0 10px 0;
  max-width: 720px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  flex-grow: 1;
`
const Textarea = styled.textarea`
  background-color: ${gs.color.brown};
  font-family: "Alegreya Sans", sans-serif;
  width: 100%;
  border: 0;
  padding: 10px;
  margin-right: 10px;
  font-size: 1rem;
  color: ${gs.color.white};
  &::placeholder {
    color: ${gs.color.primary};
  }
`
const BtnSubmit = styled(Button)`
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-icon {
    margin-right: 5px;
  }
  margin: 5px 0 0 0;
  &:disabled {
    cursor: default;
    color: ${gs.color.white};
    filter: saturate(1.25) brightness(0.7);
    &:hover,
    &:focus-visible {
      cursor: default;
      color: ${gs.color.white};
      filter: saturate(1.25) brightness(0.7);
    }
  }
`
const H3 = styled.h3`
  margin: 5px 0 0 0;
`
// #endregion

const initialState = {
  email: "",
  name: "",
  message: "",
  result: "",
}
function isValid(name, email, message) {
  if (name !== "" && email !== "" && message !== "") {
    return true
  } else {
    return false
  }
}
function reducer(state, action) {
  switch (action.type) {
    case "changed_name": {
      return {
        name: action.nextName,
        email: state.email,
        message: state.message,
        result: state.result,
      }
    }
    case "changed_email": {
      return {
        name: state.name,
        email: action.nextEmail,
        message: state.message,
        result: state.result,
      }
    }
    case "changed_message": {
      return {
        name: state.name,
        email: state.email,
        message: action.nextMessage,
        result: state.result,
      }
    }
    case "submitting": {
      return {
        name: state.name,
        email: state.email,
        message: state.message,
        result: "submitting",
      }
    }
    case "success": {
      return {
        name: "",
        email: "",
        message: "",
        result: "success",
      }
    }
    case "error": {
      return {
        name: state.name,
        email: state.email,
        message: state.message,
        result: "error",
      }
    }
    case "reset": {
      return {
        name: "",
        email: "",
        message: "",
        result: "error",
      }
    }
    default: {
      throw Error("Unknown action: " + action.type)
    }
  }
}

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

// Forward to info@wildermythsoundtrack.com
const ContactPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <Layout>
      <Main>
        <DivDefaultWrapper id="Contact" as="section">
          <Title>Contact The Composer</Title>
          <form
            name="contact"
            data-netlify="true"
            netlify-honeypot="bot-field"
            hidden
          >
            <input type="text" name="name" />
            <input type="email" name="email" />
            <textarea name="message"></textarea>
          </form>
          <Form
            id="Contact-Form"
            name="contact"
            onSubmit={async (e) => {
              if (isValid(state.name, state.email, state.message)) {
                //dispatch({ type: "submitting" })
                fetch("/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  body: encode({
                    "form-name": "contact",
                    name: state.name,
                    email: state.email,
                    message: state.message,
                  }),
                })
                  .then(() => {
                    console.log("Message Submitted!")
                    dispatch({ type: "success" })
                  })
                  .catch((error) => {
                    console.error(error)
                    dispatch({ type: "error" })
                  })
                e.preventDefault()
              }
            }}
          >
            <input type="hidden" name="form-name" value="contact" />
            <DivField>
              <Label htmlFor="name">Name:*</Label>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Jay Doe"
                value={state.name}
                onChange={(e) => {
                  dispatch({
                    type: "changed_name",
                    nextName: e.target.value,
                  })
                }}
              />
            </DivField>
            <DivField>
              <Label htmlFor="email">Email:*</Label>
              <Input
                type="text"
                id="email"
                name="email"
                placeholder="example@email.com"
                value={state.email}
                onChange={(e) => {
                  dispatch({
                    type: "changed_email",
                    nextEmail: e.target.value,
                  })
                }}
              />
            </DivField>
            <DivField>
              <Label htmlFor="email">Message:*</Label>
              <Textarea
                type="textarea"
                id="message"
                name="message"
                placeholder="Hi!! I think your music is pretty cool, and the musicians did such a great job performing it!"
                value={state.message}
                onChange={(e) => {
                  dispatch({
                    type: "changed_message",
                    nextMessage: e.target.value,
                  })
                }}
              />
            </DivField>
            {state.result === "success" ? (
              <H3>Thanks for reaching out!</H3>
            ) : (
              <BtnSubmit
                type="submit"
                value="Submit"
                disabled={!isValid(state.name, state.email, state.message)}
              >
                {state.result === "submitting" ? (
                  <>
                    <Loading />
                    Sending Message...{" "}
                  </>
                ) : (
                  "Submit"
                )}
              </BtnSubmit>
            )}
          </Form>
          {state.result === "error" && (
            <p>
              Something went wrong submitting the form. Please refresh the page
              and try again.
            </p>
          )}
        </DivDefaultWrapper>
      </Main>
    </Layout>
  )
}

export default ContactPage

export const Head = () => <Seo title="Wildermyth OST - Contact the Composer" />
